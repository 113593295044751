@import "../../breakpoints.scss";
@include for-size(xsmall) {
}

@include for-size(small) {
}

@include for-size(medium) {
}

@include for-size(large) {
}

@include for-size(xlarge) {
}

.requests_table {
  width: 100%;
  color: #fff;
  text-align: left;
  height: 100%;
  border-spacing: 0;

  & > tbody {
    display: block;
    height: calc(100% - 5.5rem);
    overflow: auto;
    font-size: 1.5rem;
    color: #ffffff;
    tr {
      cursor: pointer;
      display: flex;
      td {
        border-bottom: 0.1rem solid rgba(151, 151, 151, 0.25);
        padding: 0 1.5rem;
        height: 6rem;
        &:nth-of-type(1) {
          width: 15%;
        }
        &:nth-of-type(3) {
          width: 20%;
        }
      }
    }
  }
  & > thead,
  & > tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  & > thead {
    font-size: 1.1rem;
    color: #ffffff;
    th {
      height: 5.5rem;
      padding: 0 1.5rem;
      &:nth-of-type(1) {
        width: 15%;
      }
      &:nth-of-type(3) {
        width: 20%;
        cursor: pointer;
      }
      & > div {
        position: relative;
        .__filtered {
          color: #3254c5;
        }
        & > div {
          display: flex;
          align-items: center;
          svg {
            margin-left: 1rem;
            margin-top: -0.2rem;
          }
        }

        .filter_dropdown {
          position: absolute;
          top: 2rem;
          right: -0.5rem;
          padding: 1.5rem 1.5rem 0 1.5rem;
          font-size: 1.4rem;
          color: #ffffff;
          border-radius: 0.6rem;
          li {
            margin-bottom: 1.5rem;
            width: 100%;
            display: block;
            position: relative;
            padding-left: 3.5rem;
            cursor: pointer;
            font-size: 1.4rem;
            label:hover input ~ .checkmark {
              background-color: #2f2f2f;
            }

            /* When the checkbox is checked, add a blue background */

            label {
              user-select: none;
              cursor: pointer;
              align-items: center;
              display: flex;

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                margin-right: 1.5rem;
                &:checked ~ .checkmark {
                  border: 0.1rem solid #3254c5;
                }
              }
              .checkmark {
                position: absolute;
                transition: background 0.1s linear;
                top: 0;
                left: 0;
                height: 2rem;
                width: 2.2rem;
                border-radius: 0.6rem;
                background-color: #1e1e1e;
                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                &::after {
                  left: 0.8rem;
                  top: 0.2rem;
                  width: 0.5rem;
                  height: 1rem;
                  border: solid white;
                  border-width: 0 0.1rem 0.1rem 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
              :checked ~ .checkmark:after {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
.placeholder_row {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6a6a6a;
  font-size: 1.4rem;
}
.selected_row {
  background: rgba(216, 216, 216, 0.07);
}
