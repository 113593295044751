@import "../../breakpoints.scss";
@include for-size(xsmall) {
}

@include for-size(small) {
}

@include for-size(medium) {
}

@include for-size(large) {
}

@include for-size(xlarge) {
}

.backdrop_container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(36, 13, 44, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog_box {
    background: #000000;
    border-radius: 0.6rem;
    width: 45%;
    padding: 3.5rem 4rem;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      margin-bottom: 2rem;
      padding: 0;
      span {
        font-size: 2.2rem;
        color: #ffffff;
        letter-spacing: 0;
      }
      svg {
        margin-left: auto;
        height: 3rem;
        cursor: pointer;
      }
    }
    & > span {
      width: 100%;
      font-size: 1.6rem;
      color: #ffffff;
      letter-spacing: 0;
      margin-bottom: 3.5rem;
    }
    .buttons {
      display: flex;
      font-weight: 600;
      margin-top: 1rem;
      button {
        &:disabled {
          opacity: 0.5;
        }
      }
      .button {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3254c5;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        color: #ffffff;
        letter-spacing: 0;
        text-align: left;
        height: 3.5rem;
        transition: all 0.1s linear;
        svg {
          margin-left: 1rem;
          margin-top: -0.3rem;
        }
      }
      .confirm_button {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff5151;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        color: #ffffff;
        letter-spacing: 0;
        text-align: left;
        height: 3.5rem;
        transition: all 0.1s linear;
        svg {
          margin-left: 1rem;
          margin-top: -0.3rem;
        }
      }
    }
  }
}
