.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0!important;
    &:checked + .slider {
        background-color: rgba(58, 57, 57, 1);
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    &:checked + .slider:before {
      background-color: #36AE7C;
      -webkit-transform: translateX(17px);
      -ms-transform: translateX(17px);
      transform: translateX(17px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(58, 57, 57, 0.7);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 5px;
    bottom: 3px;
    background-color: rgb(83, 81, 81);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
